<template>
  <div>
    <v-dialog v-model="dialog" max-width="800px" persistent @input="v => v || closeEditDialog()">
      <template v-slot:activator="{ on }">
        <v-tooltip top v-if="userRole == 2 && isBetweenDates">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="mb-2 ml-4"  color="grey" dark rounded small>Dodaj nowego słuchacza</v-btn>
          </template>
          <span>W dniach 31.10.{{ returnCurrentYear }} godzina 23:59 - 31.12.{{ returnCurrentYear }} 23:59, zostaje zablokowana możliwość dodawania nowych słuchaczy.<br />Prosimy w sytuacjach wyjątkowych, o kontakt z Sekretariatem. </span>
        </v-tooltip>
        <v-btn v-else color="primary" dark rounded small class="mb-2 ml-4" v-on="on">Dodaj nowego słuchacza</v-btn>


      </template>
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        </v-card-title>
        <v-card-text>
          <RegisterFormStudent @registerFormValidated="registerFormValidated" @registerFormPrinted="registerFormPrinted"
                               @registerFormSaved="registerFormSaved" @validateStudentData="validateStudentData"
                               :validate="validate" :report="report"/>
          <v-divider class="mb-4"></v-divider>
          <v-container>
            <v-row>
              <v-col sm="6">
                <v-select
                    v-model="editedItem.date"
                    :items="getSemesterForRole()"
                    label="Termin rozpoczęcia studiów podyplomowych (semestr)"
                    :error-messages="semesterErrors"
                    item-text="value"
                    item-value="key"
                    required
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6">
                <v-select v-model="editedItem.cid"
                          :items="courses"
                          item-value="id"
                          menu-props="auto"
                          label="Kierunek studiów"
                          :error-messages="courseErrors"
                          required
                          @blur="$v.editedItem.cid.$touch()"
                >
                  <template v-slot:selection="{ item }">
                    {{ item.name }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item.name }}
                  </template>
                </v-select>
              </v-col>
              <v-col sm="6">
                <v-select v-model="editedItem.cid_2"
                          :items="courses"
                          item-value="id"
                          menu-props="auto"
                          label="Drugi kierunek (opcjonalnie)"
                >
                  <template v-slot:selection="{ item }">
                    {{ item.name }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item.name }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6">
                <v-select
                    v-model="editedItem.price"
                    :error-messages="priceErrors"
                    :items="priceChoices"
                    item-text="value"
                    item-value="key"
                    label="Czesne"
                    required
                ></v-select>
              </v-col>
              <v-col sm="6">
                <v-select
                    v-model="editedItem.price_2"
                    :items="priceChoices"
                    item-text="value"
                    item-value="key"
                    label="Czesne drugiego kierunku"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6">
                <v-select v-model="editedItem.pid"
                          :items="places"
                          item-value="id"
                          menu-props="auto"
                          label="Ośrodek"
                          single-line
                          :error-messages="placeErrors"
                          required
                          @blur="$v.editedItem.pid.$touch()"
                >
                  <template v-slot:selection="{ item }">
                    {{ item.name }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item.name }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6">
                <v-select v-model="editedItem.coordinator"
                          :items="coordinators"
                          menu-props="auto"
                          label="Studenta dodał (Koordynator)"
                          single-line
                          :error-messages="coordinatorErrors"
                          required
                          @blur="$v.editedItem.coordinator.$touch()"
                >
                  <template v-slot:selection="{ item }">
                    {{ item }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-7">
          <v-btn color="grey" class="overline" text @click="closeEditDialog">
            <v-icon small left>arrow_back</v-icon>
            <span>porzuć dodawanie i wróć do listy wszystkich słuchaczy</span></v-btn>
          <v-spacer></v-spacer>
          <v-btn class="primary" rounded @click="save">{{ formTitle }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogNewSuccess" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <v-container class="text-center">
            <v-row>
              <v-col sm="12">
                <span class="headline">Poprawnie zapisałeś użytkownika</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
          <v-container class="text-center">
            <v-row>
              <v-col sm="12">
                <v-icon x-large class="green--text">check_circle_outline</v-icon>
              </v-col>
              <v-col sm="12">
                Poprawnie zapisano użytkownika i przypisano mu kursy.
              </v-col>
              <v-col sm="12">
                <v-btn color="primary" rounded @click="dialogNewSuccess = false">Powrót do listy wszystkich słuchaczy
                </v-btn>
              </v-col>
              <v-col sm="12">
                lub
              </v-col>
              <v-col sm="12">
                <v-btn rounded color="primary" outlined @click="closeDialogNewSuccessAndAddAnotherUser">Chcę dodać
                  kolejnego użytkownika
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogNewSuccessUserExists" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <v-container class="text-center">
            <v-row>
              <v-col sm="12">
                <span class="headline">Poprawnie dodałeś kurs (użytkownik istnieje)</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
          <v-container class="text-center">
            <v-row>
              <v-col sm="12">
                <v-icon x-large class="green--text">check_circle_outline</v-icon>
              </v-col>
              <v-col sm="12">
                Użytkownik o takim adresie email już istnieje. Dane dotyczące kursu zostały zapisane, jednak w celu zmiany danych osobowych słuchacza (email, telefon, adres zamieszkania itp.), należy skontaktować się bezpośrednio z dziekanatem.
              </v-col>
              <v-col sm="12">
                <v-btn color="primary" rounded @click="dialogNewSuccessUserExists = false">Powrót do listy wszystkich słuchaczy
                </v-btn>
              </v-col>
              <v-col sm="12">
                lub
              </v-col>
              <v-col sm="12">
                <v-btn color="primary" outlined rounded @click="addAnotherStudentCourseAndUser">Chcę dodać
                  kolejnego użytkownika
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogNewError" max-width="800px">
      <v-card>
        <v-card-title>
          <v-container class="text-center">
            <v-row>
              <v-col sm="12">
                <span class="headline">Błąd podczas dodawania użytkownika</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
          <v-container class="text-center">
            <v-row>
              <v-col sm="12">
                <v-icon x-large class="red--text">warning</v-icon>
              </v-col>
              <v-col sm="12">
                Wygląda na to że użytkownik o takim adresie email jest już zapisany na taki kierunek w tym naborze.
              </v-col>
              <v-col sm="12">
                <v-btn rounded color="primary" @click="dialogNewError = false">Zamknij</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import RegisterFormStudent from "./RegisterFormStudent"
import {mapActions, mapGetters} from "vuex";
import {required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import moment from "moment";
import {PriceChoicesEnum, SemesterChoicesEnum} from "../enums";

export default {
  mixins: [validationMixin],
  name: 'StudentCourseAndUserAdd',
  components: {RegisterFormStudent},
  data: () => ({
    validated: false,
    dialogNewSuccess: false,
    dialogNewSuccessUserExists: false,
  }),
  props: {
    closeEditDialog: {},
    closeDialogNewSuccessAndAddAnotherUser: {},
    dialog: {},
    dialogNewError: {},
    editedItem: {},
    formTitle: {},
    report: {},
    selected: {},
    validate: {}
  },
  validations: {
    editedItem: {
      date: {required},
      cid: {required},
      pid: {required},
      price: {required},
      coordinator: {required}
    }
  },
  computed: {
    ...mapGetters({
      userRole: 'users/userRole',
      places: 'places/getPlaces',
      courses: 'courses/getCoursesWithoutAllOption',
      coordinators: 'places/getCoordinators',
    }),
    semesterErrors () {
      const errors = [];
      if (!this.$v.editedItem.date.$dirty) return errors;
      !this.$v.editedItem.date.required && errors.push('Podanie terminu rozpoczęcia jest wymagane');
      return errors
    },
    courseErrors () {
      const errors = [];
      if (!this.$v.editedItem.cid.$dirty) return errors;
      !this.$v.editedItem.cid.required && errors.push('Podanie kierunku studiów jest wymagane');
      return errors
    },
    placeErrors () {
      const errors = [];
      if (!this.$v.editedItem.pid.$dirty) return errors;
      !this.$v.editedItem.pid.required && errors.push('Podanie miejscowości jest wymagane');
      return errors
    },
    priceErrors () {
      const errors = [];
      if (!this.$v.editedItem.price.$dirty) return errors;
      !this.$v.editedItem.price.required && errors.push('Podanie czesnego jest wymagane');
      return errors
    },
    coordinatorErrors () {
      const errors = [];
      if (!this.$v.editedItem.coordinator.$dirty) return errors;
      !this.$v.editedItem.coordinator.required && errors.push('Podanie koordynatora jest wymagane');
      return errors
    },
    returnCurrentYear() {
      return moment().format('YYYY');
    },
    isBetweenDates() {
      var startDate   = moment("01/11/" + moment().format('YYYY'), "DD/MM/YYYY");
      var endDate     = moment("31/12/" + moment().format('YYYY'), "DD/MM/YYYY");

      return moment().isBetween(startDate, endDate);
    },
    semesterChoices() {
      return SemesterChoicesEnum
    },
    priceChoices() {
      return PriceChoicesEnum
    },
  },
  methods: {
    ...mapActions({
      createStudentCourse: 'studentCourses/createStudentCourse',
      editStudentCourse: 'studentCourses/editStudentCourse',
    }),
    emit (eventName, value) {
      return new Promise((resolve, reject) => {
        this.validate = value
        this.$nextTick(resolve)
      }).catch((error) => { console.log(error) });
    },
    registerFormValidated(value) {
      this.validate = false
      this.validated = value
    },
    save: async function () {
      await this.emit('validateStudentData', true)
      this.$v.$touch();
      if (!this.$v.$invalid && this.validated) {
        this.createStudentCourse(this.editedItem).then(response => {
          if (response == 200) {
            this.$log.info('Saved new student course', response);
            this.closeEditDialog();
            this.$v.$reset();
            this.dialogNewSuccess = true;
          } else if (response == 201) {
            this.$log.info('Saved new student course (user exists) ', response);
            this.dialogNewSuccessUserExists = true;
            this.closeEditDialog();
          }
        }).catch(error => {
          this.$log.error('Saving new user course error', error);
          this.dialogNewError = true;
        })
      }
    },
    getSemesterForRole() {
      //new
      if (this.userRole == 2) {
        return this.semesterChoices[0];
      } else {
        return this.semesterChoices;
      }
    },
    addAnotherStudentCourseAndUser() {
      this.dialogNewSuccessUserExists = false;
      this.$v.$reset();
      this.closeDialogNewSuccessAndAddAnotherUser();
    }
  }
}
</script>
