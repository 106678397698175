<template>
  <div>
    <v-dialog v-model="dialog" max-width="800px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col sm="12">
                <v-select
                    v-model="editedItem.date"
                    :disabled="editedIndex > -1 ? true : false"
                    :error-messages="semesterErrors"
                    :items="getSemesterForRole()"
                    :readonly="editedIndex > -1 ? true : false"
                    item-text="value"
                    item-value="key"
                    label="Termin rozpoczęcia studiów podyplomowych (semestr)"
                    required
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12">
                <v-select v-model="editedItem.cid"
                          :error-messages="courseErrors"
                          :items="courses"
                          item-value="id"
                          label="Kierunek studiów"
                          menu-props="auto"
                          required
                          @blur="$v.editedItem.cid.$touch()"
                >
                  <template v-slot:selection="{ item }">
                    {{ item.name }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item.name }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="userRole == 1" sm="12">
                <v-text-field
                    v-model="customPrice"
                    :error-messages="customPriceErrors"
                    hint="Proszę podać wyłącznie cyfry"
                    label="Czesne"
                    suffix="zł"
                ></v-text-field>
                <v-text-field v-model="editedItem.price" :error-messages="priceErrors" label="Czesne" required style="display: none;"></v-text-field>
              </v-col>
              <v-col v-else sm="12">
                <v-select
                    v-model="editedItem.price"
                    :error-messages="priceErrors"
                    :items="priceChoices"
                    item-text="value"
                    item-value="key"
                    label="Czesne"
                    required
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12">
                <v-select v-model="editedItem.pid"
                          :error-messages="placeErrors"
                          :items="places"
                          item-value="id"
                          label="Ośrodek"
                          menu-props="auto"
                          required
                          @blur="$v.editedItem.pid.$touch()"
                >
                  <template v-slot:selection="{ item }">
                    {{ item.name }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item.name }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12">
                <v-select v-model="editedItem.coordinator"
                          :error-messages="coordinatorErrors"
                          :items="coordinators"
                          label="Studenta dodał (Koordynator)"
                          menu-props="auto"
                          required
                          @blur="$v.editedItem.coordinator.$touch()"
                >
                  <template v-slot:selection="{ item }">
                    {{ item }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-7">
          <v-btn class="overline" color="grey" text @click="closeEditDialog">
            <v-icon left small>arrow_back</v-icon>
            <span>porzuć dodawanie i wróć do szczegółów słuchacza</span></v-btn>
          <v-spacer></v-spacer>
          <v-btn class="primary" rounded @click="save">Zapisz</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogNewSuccess" max-width="800px" persistent>
      <v-card>
        <v-card-title>
          <v-container class="text-center">
            <v-row>
              <v-col sm="12">
                <span class="headline">Poprawnie zapisałeś szczegóły kursu</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
          <v-container class="text-center">
            <v-row>
              <v-col sm="12">
                <v-icon class="green--text" x-large>check_circle_outline</v-icon>
              </v-col>
              <v-col sm="12">
                Poprawnie zapisano kurs użytkownika.
              </v-col>
              <v-col sm="12">
                <v-btn color="primary" rounded @click="dialogNewSuccess = false">Powrót do szczegółów słuchacza
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogNewError" max-width="800px" persistent>
      <v-card>
        <v-card-title>
          <v-container class="text-center">
            <v-row>
              <v-col sm="12">
                <span class="headline">Błąd podczas dodawania kursu dla użytkownika</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
          <v-container class="text-center">
            <v-row>
              <v-col sm="12">
                <v-icon class="red--text" x-large>warning</v-icon>
              </v-col>
              <v-col sm="12">
                Wygląda na to, że użytkownik o takim adresie email jest już zapisany na taki kierunek w tym naborze. Nie dodano nowego kursu.
              </v-col>
              <v-col sm="12">
                <v-btn color="primary" rounded @click="dialogNewError = false">Zamknij</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {numeric, required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import moment from "moment";
import {PriceChoicesEnum, SemesterChoicesEnum} from "../enums";

export default {
  mixins: [validationMixin],
  name: 'StudentCourseAddAndEdit',
  components: {},
  data: () => ({
    dialogNewSuccess: false,
    dialogNewError: false,
  }),
  props: {
    dialog: {},
    closeEditDialog: {},
    editedItem: {},
    validate: {},
    editedIndex: {}
  },
  validations: {
    editedItem: {
      date: {required},
      cid: {required},
      pid: {required},
      price: {required, numeric},
      coordinator: {required}
    },
    customPrice: {required, numeric},
  },
  computed: {
    ...mapGetters({
      userRole: 'users/userRole',
      places: 'places/getPlaces',
      courses: 'courses/getCoursesWithoutAllOption',
      coordinators: 'places/getCoordinators',
    }),
    semesterErrors () {
      const errors = [];
      if (!this.$v.editedItem.date.$dirty) return errors;
      !this.$v.editedItem.date.required && errors.push('Podanie terminu rozpoczęcia jest wymagane');
      return errors
    },
    courseErrors () {
      const errors = [];
      if (!this.$v.editedItem.cid.$dirty) return errors;
      !this.$v.editedItem.cid.required && errors.push('Podanie kierunku studiów jest wymagane');
      return errors
    },
    placeErrors () {
      const errors = [];
      if (!this.$v.editedItem.pid.$dirty) return errors;
      !this.$v.editedItem.pid.required && errors.push('Podanie miejscowości jest wymagane');
      return errors
    },
    customPriceErrors () {
      const errors = [];
      if (!this.$v.customPrice.$dirty) return errors;
      !this.$v.customPrice.required && errors.push('Podanie czesnego jest wymagane');
      !this.$v.customPrice.numeric && errors.push('Proszę podać wyłącznie cyfry');
      return errors
    },
    priceErrors () {
      const errors = [];
      if (!this.$v.editedItem.price.$dirty) return errors;
      !this.$v.editedItem.price.required && errors.push('Podanie czesnego jest wymagane');
      !this.$v.editedItem.price.numeric && errors.push('Proszę podać wyłącznie cyfry');
      return errors
    },
    coordinatorErrors () {
      const errors = [];
      if (!this.$v.editedItem.coordinator.$dirty) return errors;
      !this.$v.editedItem.coordinator.required && errors.push('Podanie koordynatora jest wymagane');
      return errors
    },
    returnCurrentYear() {
      return moment().format('YYYY');
    },
    isBetweenDates() {
      var startDate   = moment("01/11/" + moment().format('YYYY'), "DD/MM/YYYY");
      var endDate     = moment("31/12/" + moment().format('YYYY'), "DD/MM/YYYY");

      return moment().isBetween(startDate, endDate);
    },
    semesterChoices() {
      return SemesterChoicesEnum
    },
    priceChoices() {
      return PriceChoicesEnum
    },
    formTitle () {
      return this.editedIndex === -1 ? 'Dodaj nowy kierunek' : 'Edytuj kierunek'
    },
    customPrice: {
      get(){
        if (this.editedItem.price === '') {
          return '';
        } else {
          return this.editedItem.price/100;
        }
      },
      set(value){
        this.$v.$touch();
        this.editedItem.price = value * 100;
      }
    },
  },
  mounted() {

  },
  methods: {
    ...mapActions({
      createStudentCourse: 'studentCourses/createStudentCourse',
      editStudentCourse: 'studentCourses/editStudentCourse',
      resetChosenStudentCourse: 'studentCourses/resetChosenStudentCourse',
    }),
    emit (eventName, value) {
      return new Promise((resolve, reject) => {
        this.validate = value
        this.$nextTick(resolve)
      }).catch((error) => { console.log(error) });
    },
    save: async function () {
      await this.emit('validateStudentData', true)
      this.$v.$touch();
      if (this.editedIndex > -1) {
        if (!this.$v.$invalid) {
          if(confirm('Jeśli zmieniono kierunek, dzienniki i oceny dla słuchacza starego kierunku zostaną usunięte. Czy na pewno chcesz wprowadzić zmianę?')){
            this.editStudentCourse(this.editedItem).then(response => {
              if (response == 200) {
                this.$log.info('Saved edited student course', response);
                this.closeEditDialog()
                this.dialogNewSuccess = true;
                this.$v.$reset();
              }
            }).catch(error => {
              this.closeEditDialog();
              this.dialogNewError = true;
              this.$log.error('Saving edited user error', error)
            })
          }
        }
      } else {
        if (!this.$v.$invalid) {
          this.createStudentCourse(this.editedItem).then(response => {
            if (response == 200 || response == 201) {
              this.$log.info('Saved new student course', response);
              this.closeEditDialog();
              this.dialogNewSuccess = true;
              this.$v.$reset();
            } else if (response == 409) {
              this.$log.info('Course exists and student exists', response);
              this.closeEditDialog();
              this.dialogNewError = true;
              this.$v.$reset();
            }
          }).catch(error => {
            this.$log.error('Saving new user course error', error);
            this.closeEditDialog();
            this.$v.$reset();
            this.dialogNewError = true;
          })
        }
      }
    },
    getSemesterForRole() {
      // edit
      if (this.editedIndex > -1) {
        return this.semesterChoices;
      } else { //new
        if (this.userRole == 2) {
          return this.semesterChoices[0];
        } else {
          return this.semesterChoices;
        }
      }
    }
  }
}
</script>
